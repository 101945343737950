.contactContainer {
  font-size: calc(10px + 2vmin);
  color: white;
  bottom: 0;
  left: 0;
  right: 0;
  /* width: 100%; */
  height: 150px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-wrap: wrap;
  padding-left: 20vw;
  padding-right: 20vw;
}

#title {
  flex-basis: 100%;
  /* grid-column-start: span 5;
  /* grid-column-end: 3; */
  /* grid-row-start: 0; */ 
}

.break {
  flex-basis: 100%;
  height: 0;
}

.logo {
  height: auto;
  width: auto;
}
.icon {
  height: calc(10px + 3vmin);
  /* display: inline-block; */
}

.iconContainer {
  /* height: 100%; */

  flex-grow: 1;
  /* text-align: center; */
  
  display: flex;
  justify-content: center;
  align-items: center;
}
div {
  background-color: black;
}

.navbar {
  color: white;
  width: 100vw;
  position: fixed;
  top: 0px;
  height: 75px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}